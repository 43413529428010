<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>지점관리</h1>
            <div class="is-right">
            </div>
        </div>

        <div class="jh-cols">
            <!--첫번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox1" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM1"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT1"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf1">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles1">파일삭제</v-btn>
                            <!-- 파일 업로드 모달창 -->
                            <v-dialog max-width="800" persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone" content-class="square-modal min-auto">
                            <dialog-VueDropzone
                                headerTitle="첨부파일 등록"
                                :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
                                :fileGroupKey    = this.dropZoneParam.fileGroupKey
                                :pathType        = this.dropZoneParam.pathType
                                :busiType        = this.dropZoneParam.busiType
                                :acceptedFiles   = this.dropZoneParam.acceptedFiles
                                :userOptions     = this.dropZoneParam.userOptions
                                @hide="hideDialog('VueDropzone')"
                                @submit="uploadFileResponse"
                            >
                            </dialog-VueDropzone>
                            </v-dialog>
                            <!-- 파일 업로드 모달창 -->
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST1" >{{ORIGINAL_FILENAME1}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//첫번째-->
            <!--두번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox2" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM2"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT2"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf2">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles2">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST2" >{{ORIGINAL_FILENAME2}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//두번째-->
            <!--세번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox3" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM3"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT3"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf3">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles3">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST3" >{{ORIGINAL_FILENAME3}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//세번째-->
            <!--네번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox4" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM4"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT4"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf4">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles4">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST4" >{{ORIGINAL_FILENAME4}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//네번째-->
        </div>

        <div class="jh-cols">
            <!--다섯번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox5" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM5"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT5"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf5">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles5">파일삭제</v-btn>
                            <!-- 파일 업로드 모달창 -->
                            <v-dialog max-width="800" persistent v-model="dialogVueDropzone" v-if="dialogVueDropzone" content-class="square-modal min-auto">
                            <dialog-VueDropzone
                                headerTitle="첨부파일 등록"
                                :ASP_NEWCUST_KEY = this.dropZoneParam.ASP_NEWCUST_KEY
                                :fileGroupKey    = this.dropZoneParam.fileGroupKey
                                :pathType        = this.dropZoneParam.pathType
                                :busiType        = this.dropZoneParam.busiType
                                :acceptedFiles   = this.dropZoneParam.acceptedFiles
                                :userOptions     = this.dropZoneParam.userOptions
                                @hide="hideDialog('VueDropzone')"
                                @submit="uploadFileResponse"
                            >
                            </dialog-VueDropzone>
                            </v-dialog>
                            <!-- 파일 업로드 모달창 -->
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST5" >{{ORIGINAL_FILENAME5}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//다섯번째-->
            <!--여섯번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox6" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM6"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT6"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf6">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles6">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST6" >{{ORIGINAL_FILENAME6}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//여섯번째-->
            <!--일곱번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox7" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM7"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT7"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf7">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles7">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST7" >{{ORIGINAL_FILENAME7}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//일곱번째-->
            <!--여덟번째-->
            <div class="jh-form-wrap">
                <dl class="jh-list-form">
                    <dt>
                        <label class="is-required">지점명</label>
                        <div class="is-right">
                            <v-checkbox class="jh-check" v-model="checkbox8" label="사용안함"></v-checkbox>
                        </div>
                    </dt>
                    <dd><input type="text" class="jh-form is-md" v-model="BR_NM8"></dd>
                    <dt><label>지점내용</label></dt>
                    <dd><textarea class="jh-form" style="height: 250px;" v-model="BR_CONT8"></textarea></dd>
                    <dt>
                        <label>약도</label>
                        <div class="is-right">
                            <v-btn class="jh-btn" @click="clickFileClsf8">파일등록</v-btn>
                            <v-btn class="jh-btn is-del" @click="clickDelFiles8">파일삭제</v-btn>
                        </div>
                    </dt>
                    <dd class="is-block">
                        <p class="jh-desc" v-if="FILE_EXIST8" >{{ORIGINAL_FILENAME8}}</p>
                        <p class="jh-desc" v-else>등록된 파일이 없습니다.</p>
                    </dd>
                </dl>
            </div>
            <!--//여덟번째-->
        </div>
        <div class="jh-btn-area is-mt-20 is-pb-30">
            <!-- <v-btn class="jh-btn is-lg is-fill" style="width: 150px;">저장하기</v-btn> -->
            <!-- <v-btn class="jh-btn is-lg is-fill" id="btnSave" @click="common_confirm('저장하시겠습니까?',submitGrid1,'',null,'','done')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')">저장하기</v-btn> -->
            <v-btn class="jh-btn is-lg is-fill" id="btnSave" @click="common_confirm('저장하시겠습니까?',saveBrInfo,'',null,'','done')">저장하기</v-btn>
            
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {mixin} from "../../mixin/mixin";
import DialogFileUploadPop from "@/components/FileUploadPop.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import DialogVueDropzone from "@/components/VueDropzone";
  
export default {
    name: "MENU_E040101", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        vueDropzone: vue2Dropzone,
        DialogFileUploadPop,
        DialogVueDropzone,
    },
    data() {
        return {
        BR_NM1 :'',
        BR_NM2 :'',
        BR_NM3 :'',
        BR_NM4 :'',
        BR_NM5 :'',
        BR_NM6 :'',
        BR_NM7 :'',
        BR_NM8 :'',
        BR_CONT1 :'',
        BR_CONT2:'',
        BR_CONT3 :'',
        BR_CONT4:'',
        BR_CONT5 :'',
        BR_CONT6:'',
        BR_CONT7 :'',
        BR_CONT8:'',
        FILE_GROUP_KEY1:'',
        FILE_GROUP_KEY2:'',
        FILE_GROUP_KEY3:'',
        FILE_GROUP_KEY4:'',
        FILE_GROUP_KEY5:'',
        FILE_GROUP_KEY6:'',
        FILE_GROUP_KEY7:'',
        FILE_GROUP_KEY8:'',
        FILE_KEY1:'',
        FILE_KEY2:'',
        FILE_KEY3:'',
        FILE_KEY4:'',
        FILE_KEY5:'',
        FILE_KEY6:'',
        FILE_KEY7:'',
        FILE_KEY8:'',
        SORT_ORD1:'',
        SORT_ORD2:'',
        SORT_ORD3:'',
        SORT_ORD4:'',
        SORT_ORD5:'',
        SORT_ORD6:'',
        SORT_ORD7:'',
        SORT_ORD8:'',
        ORIGINAL_FILENAME1:'',
        ORIGINAL_FILENAME2:'',
        ORIGINAL_FILENAME3:'',
        ORIGINAL_FILENAME4:'',
        ORIGINAL_FILENAME5:'',
        ORIGINAL_FILENAME6:'',
        ORIGINAL_FILENAME7:'',
        ORIGINAL_FILENAME8:'',
        SAVE_FILENAME1:'',
        SAVE_FILENAME2:'',
        SAVE_FILENAME3:'',
        SAVE_FILENAME4:'',
        SAVE_FILENAME5:'',
        SAVE_FILENAME6:'',
        SAVE_FILENAME7:'',
        SAVE_FILENAME8:'',
        FILE_PATH1:'',
        FILE_PATH2:'',
        FILE_PATH3:'',
        FILE_PATH4:'',
        FILE_PATH5:'',
        FILE_PATH6:'',
        FILE_PATH7:'',
        FILE_PATH8:'',

        FILE_CLSF:'',
        FILE_EXIST1:'',
        FILE_EXIST2:'',
        FILE_EXIST3:'',
        FILE_EXIST4:'',
        FILE_EXIST5:'',
        FILE_EXIST6:'',
        FILE_EXIST7:'',
        FILE_EXIST8:'',

        // 체크박스
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: true,
        checkbox6: true,
        checkbox7: true,
        checkbox8: true,

        FILE_GROUP_KEY : "",
        FILE_KEY:'',
        // 첨부파일, 첨부파일업로드 팝업 변수
        dialogVueDropzone: false,
        dropZoneParam: {},
        gridDataText: [], //첨부파일 리스트
        gridListSelected: [], //파일업로드 팝업 변수

        DialogFileUploadPop: false,
        fileInfo: {},

        }
    },
    created() { 
        this.selectBrInfo();
        // this.saveBrInfo()
    },
    computed: {
        ...mapGetters({
            geUserRole: 'userStore/GE_USER_ROLE',
            geUserCompany: 'userStore/GE_USER_COMPANY'
        }),
        initHeaders(){
            return {
                SERVICE: 'setting.system.cmmn-brInfo-manage',
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    methods: {
        async saveBrInfo(){
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/setting/agent/brInfo/insertBrInfo";
            requestData.headers["METHOD"] = "regist";
            requestData.headers["ASYNC"] = false;
            requestData.sendData["BR_NM1"] = this.restoreXSS_Dec(this.BR_NM1);
            requestData.sendData["BR_NM2"] = this.restoreXSS_Dec(this.BR_NM2);
            requestData.sendData["BR_NM3"] = this.restoreXSS_Dec(this.BR_NM3);
            requestData.sendData["BR_NM4"] = this.restoreXSS_Dec(this.BR_NM4);
            requestData.sendData["BR_NM5"] = this.restoreXSS_Dec(this.BR_NM5);
            requestData.sendData["BR_NM6"] = this.restoreXSS_Dec(this.BR_NM6);
            requestData.sendData["BR_NM7"] = this.restoreXSS_Dec(this.BR_NM7);
            requestData.sendData["BR_NM8"] = this.restoreXSS_Dec(this.BR_NM8);

    //               strText = this.replaceAll(strText,"<","&lt;");
    //   strText = this.replaceAll(strText,">","&gt;");

            // requestData.sendData["BR_CONT1"] = this.BR_CONT1;
            // requestData.sendData["BR_CONT2"] = this.BR_CONT2;
            // requestData.sendData["BR_CONT3"] = this.BR_CONT3;
            // requestData.sendData["BR_CONT4"] = this.BR_CONT4;
            requestData.sendData["BR_CONT1"] = this.restoreXSS_Dec(this.BR_CONT1);
            requestData.sendData["BR_CONT2"] = this.restoreXSS_Dec(this.BR_CONT2);
            requestData.sendData["BR_CONT3"] = this.restoreXSS_Dec(this.BR_CONT3);
            requestData.sendData["BR_CONT4"] = this.restoreXSS_Dec(this.BR_CONT4);
            requestData.sendData["BR_CONT5"] = this.restoreXSS_Dec(this.BR_CONT5);
            requestData.sendData["BR_CONT6"] = this.restoreXSS_Dec(this.BR_CONT6);
            requestData.sendData["BR_CONT7"] = this.restoreXSS_Dec(this.BR_CONT7);
            requestData.sendData["BR_CONT8"] = this.restoreXSS_Dec(this.BR_CONT8);
            requestData.sendData["FILE_GROUP_KEY1"] = this.FILE_GROUP_KEY1;
            requestData.sendData["FILE_GROUP_KEY2"] = this.FILE_GROUP_KEY2;
            requestData.sendData["FILE_GROUP_KEY3"] = this.FILE_GROUP_KEY3;
            requestData.sendData["FILE_GROUP_KEY4"] = this.FILE_GROUP_KEY4;
            requestData.sendData["FILE_GROUP_KEY5"] = this.FILE_GROUP_KEY5;
            requestData.sendData["FILE_GROUP_KEY6"] = this.FILE_GROUP_KEY6;
            requestData.sendData["FILE_GROUP_KEY7"] = this.FILE_GROUP_KEY7;
            requestData.sendData["FILE_GROUP_KEY8"] = this.FILE_GROUP_KEY8;
            requestData.sendData["FILE_KEY1"] = this.FILE_KEY1;
            requestData.sendData["FILE_KEY2"] = this.FILE_KEY2;
            requestData.sendData["FILE_KEY3"] = this.FILE_KEY3;
            requestData.sendData["FILE_KEY4"] = this.FILE_KEY4;
            requestData.sendData["FILE_KEY5"] = this.FILE_KEY5;
            requestData.sendData["FILE_KEY6"] = this.FILE_KEY6;
            requestData.sendData["FILE_KEY7"] = this.FILE_KEY7;
            requestData.sendData["FILE_KEY8"] = this.FILE_KEY8;
            requestData.sendData["USE_YN1"] = this.checkbox1 ? 'N' : 'Y';
            requestData.sendData["USE_YN2"] = this.checkbox2 ? 'N' : 'Y';
            requestData.sendData["USE_YN3"] = this.checkbox3 ? 'N' : 'Y';
            requestData.sendData["USE_YN4"] = this.checkbox4 ? 'N' : 'Y';
            requestData.sendData["USE_YN5"] = this.checkbox5 ? 'N' : 'Y';
            requestData.sendData["USE_YN6"] = this.checkbox6 ? 'N' : 'Y';
            requestData.sendData["USE_YN7"] = this.checkbox7 ? 'N' : 'Y';
            requestData.sendData["USE_YN8"] = this.checkbox8 ? 'N' : 'Y';

            requestData.sendData["USER_ID"] = this.geUserRole.userId;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                this.common_alert("성공적으로 저장했습니다.");
                this.selectBrInfo();
            }

        },
        async selectBrInfo(){
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };
            requestData.headers["URL"] = "/api/setting/agent/brInfo/selectBrInfo";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                // let dataList = response.DATA;
                // this.gridDataText = dataList;
                
                this.BR_NM1 = this.restoreXSS(response.DATA[0].BR_NM1).replace(/\r\n/g, "\n");
                this.BR_NM2 = this.restoreXSS(response.DATA[0].BR_NM2).replace(/\r\n/g, "\n");
                this.BR_NM3 = this.restoreXSS(response.DATA[0].BR_NM3).replace(/\r\n/g, "\n");
                this.BR_NM4 = this.restoreXSS(response.DATA[0].BR_NM4).replace(/\r\n/g, "\n");
                this.BR_NM5 = this.restoreXSS(response.DATA[0].BR_NM5).replace(/\r\n/g, "\n");
                this.BR_NM6 = this.restoreXSS(response.DATA[0].BR_NM6).replace(/\r\n/g, "\n");
                this.BR_NM7 = this.restoreXSS(response.DATA[0].BR_NM7).replace(/\r\n/g, "\n");
                this.BR_NM8 = this.restoreXSS(response.DATA[0].BR_NM8).replace(/\r\n/g, "\n");
                this.BR_CONT1 = this.restoreXSS(response.DATA[0].BR_CONT1).replace(/\r\n/g, "\n");
                this.BR_CONT2 = this.restoreXSS(response.DATA[0].BR_CONT2).replace(/\r\n/g, "\n");
                this.BR_CONT3 = this.restoreXSS(response.DATA[0].BR_CONT3).replace(/\r\n/g, "\n");
                this.BR_CONT4 = this.restoreXSS(response.DATA[0].BR_CONT4).replace(/\r\n/g, "\n");
                this.BR_CONT5 = this.restoreXSS(response.DATA[0].BR_CONT5).replace(/\r\n/g, "\n");
                this.BR_CONT6 = this.restoreXSS(response.DATA[0].BR_CONT6).replace(/\r\n/g, "\n");
                this.BR_CONT7 = this.restoreXSS(response.DATA[0].BR_CONT7).replace(/\r\n/g, "\n");
                this.BR_CONT8 = this.restoreXSS(response.DATA[0].BR_CONT8).replace(/\r\n/g, "\n");
                this.FILE_GROUP_KEY1 = response.DATA[0].FILE_GROUP_KEY1;
                this.FILE_GROUP_KEY2 = response.DATA[0].FILE_GROUP_KEY2;
                this.FILE_GROUP_KEY3 = response.DATA[0].FILE_GROUP_KEY3;
                this.FILE_GROUP_KEY4 = response.DATA[0].FILE_GROUP_KEY4;
                this.FILE_GROUP_KEY5 = response.DATA[0].FILE_GROUP_KEY5;
                this.FILE_GROUP_KEY6 = response.DATA[0].FILE_GROUP_KEY6;
                this.FILE_GROUP_KEY7 = response.DATA[0].FILE_GROUP_KEY7;
                this.FILE_GROUP_KEY8 = response.DATA[0].FILE_GROUP_KEY8;
                this.FILE_KEY1 = response.DATA[0].FILE_KEY1;
                this.FILE_KEY2 = response.DATA[0].FILE_KEY2;
                this.FILE_KEY3 = response.DATA[0].FILE_KEY3;    
                this.FILE_KEY4 = response.DATA[0].FILE_KEY4;
                this.FILE_KEY5 = response.DATA[0].FILE_KEY5;
                this.FILE_KEY6 = response.DATA[0].FILE_KEY6;
                this.FILE_KEY7 = response.DATA[0].FILE_KEY7;    
                this.FILE_KEY8 = response.DATA[0].FILE_KEY8;
                this.SORT_ORD1 = response.DATA[0].SORT_ORD1;
                this.SORT_ORD2 = response.DATA[0].SORT_ORD2;
                this.SORT_ORD3 = response.DATA[0].SORT_ORD3;
                this.SORT_ORD4 = response.DATA[0].SORT_ORD4;
                this.SORT_ORD5 = response.DATA[0].SORT_ORD5;
                this.SORT_ORD6 = response.DATA[0].SORT_ORD6;
                this.SORT_ORD7 = response.DATA[0].SORT_ORD7;
                this.SORT_ORD8 = response.DATA[0].SORT_ORD8;
                this.ORIGINAL_FILENAME1 = response.DATA[0].ORIGINAL_FILENAME1;
                this.ORIGINAL_FILENAME2 = response.DATA[0].ORIGINAL_FILENAME2;
                this.ORIGINAL_FILENAME3 = response.DATA[0].ORIGINAL_FILENAME3;
                this.ORIGINAL_FILENAME4 = response.DATA[0].ORIGINAL_FILENAME4;
                this.ORIGINAL_FILENAME5 = response.DATA[0].ORIGINAL_FILENAME5;
                this.ORIGINAL_FILENAME6 = response.DATA[0].ORIGINAL_FILENAME6;
                this.ORIGINAL_FILENAME7 = response.DATA[0].ORIGINAL_FILENAME7;
                this.ORIGINAL_FILENAME8 = response.DATA[0].ORIGINAL_FILENAME8;
                this.SAVE_FILENAME1 = response.DATA[0].SAVE_FILENAME1;
                this.SAVE_FILENAME2 = response.DATA[0].SAVE_FILENAME2;
                this.SAVE_FILENAME3 = response.DATA[0].SAVE_FILENAME3;
                this.SAVE_FILENAME4 = response.DATA[0].SAVE_FILENAME4;
                this.SAVE_FILENAME5 = response.DATA[0].SAVE_FILENAME5;
                this.SAVE_FILENAME6 = response.DATA[0].SAVE_FILENAME6;
                this.SAVE_FILENAME7 = response.DATA[0].SAVE_FILENAME7;
                this.SAVE_FILENAME8 = response.DATA[0].SAVE_FILENAME8;
                this.FILE_PATH1 = response.DATA[0].FILE_PATH1;
                this.FILE_PATH2 = response.DATA[0].FILE_PATH2;
                this.FILE_PATH3 = response.DATA[0].FILE_PATH3;
                this.FILE_PATH4 = response.DATA[0].FILE_PATH4;
                this.FILE_PATH5 = response.DATA[0].FILE_PATH5;
                this.FILE_PATH6 = response.DATA[0].FILE_PATH6;
                this.FILE_PATH7 = response.DATA[0].FILE_PATH7;
                this.FILE_PATH8 = response.DATA[0].FILE_PATH8;
                this.checkbox1 = response.DATA[0].USE_YN1 == 'Y' ? false : true;
                this.checkbox2 = response.DATA[0].USE_YN2 == 'Y' ? false : true;
                this.checkbox3 = response.DATA[0].USE_YN3 == 'Y' ? false : true;
                this.checkbox4 = response.DATA[0].USE_YN4 == 'Y' ? false : true;
                this.checkbox5 = response.DATA[0].USE_YN5 == 'Y' ? false : true;
                this.checkbox6 = response.DATA[0].USE_YN6 == 'Y' ? false : true;
                this.checkbox7 = response.DATA[0].USE_YN7 == 'Y' ? false : true;
                this.checkbox8 = response.DATA[0].USE_YN8 == 'Y' ? false : true;

                if (this.ORIGINAL_FILENAME1 == ''){ 
                    this.FILE_EXIST1 = false;
                }else{
                    this.FILE_EXIST1 = true;
                }

                if (this.ORIGINAL_FILENAME2 == ''){ 
                    this.FILE_EXIST2 = false;
                }else{
                    this.FILE_EXIST2 = true;
                }

                if (this.ORIGINAL_FILENAME3 == ''){ 
                    this.FILE_EXIST3 = false;
                }else{
                    this.FILE_EXIST3 = true;
                }

                if (this.ORIGINAL_FILENAME4 == ''){ 
                    this.FILE_EXIST4 = false;
                }else{
                    this.FILE_EXIST4 = true;
                }

                if (this.ORIGINAL_FILENAME5 == ''){ 
                    this.FILE_EXIST5 = false;
                }else{
                    this.FILE_EXIST5 = true;
                }

                if (this.ORIGINAL_FILENAME6 == ''){ 
                    this.FILE_EXIST6 = false;
                }else{
                    this.FILE_EXIST6 = true;
                }

                if (this.ORIGINAL_FILENAME7 == ''){ 
                    this.FILE_EXIST7 = false;
                }else{
                    this.FILE_EXIST7 = true;
                }

                if (this.ORIGINAL_FILENAME8 == ''){ 
                    this.FILE_EXIST8 = false;
                }else{
                    this.FILE_EXIST8 = true;
                }

            }
        },

        //*********************************
        //          첨부파일 삭제
        //*********************************,
        clickDelFiles1(){
            this.FILE_CLSF = "1";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY1;
            this.FILE_KEY  = this.FILE_KEY1;
            this.clickDelFiles();
        },
        clickDelFiles2(){
            this.FILE_CLSF = "2";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY2;
            this.FILE_KEY  = this.FILE_KEY2;
            this.clickDelFiles();
        },
        clickDelFiles3(){
            this.FILE_CLSF = "3";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY3;
            this.FILE_KEY  = this.FILE_KEY3;
            this.clickDelFiles();
        },
        clickDelFiles4(){
            this.FILE_CLSF = "4";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY4;
            this.FILE_KEY  = this.FILE_KEY4;
            this.clickDelFiles();
        },
        clickDelFiles5(){
            this.FILE_CLSF = "5";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY5;
            this.FILE_KEY  = this.FILE_KEY5;
            this.clickDelFiles();
        },
        clickDelFiles6(){
            this.FILE_CLSF = "6";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY6;
            this.FILE_KEY  = this.FILE_KEY6;
            this.clickDelFiles();
        },
        clickDelFiles7(){
            this.FILE_CLSF = "7";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY7;
            this.FILE_KEY  = this.FILE_KEY7;
            this.clickDelFiles();
        },
        clickDelFiles8(){
            this.FILE_CLSF = "8";
            this.FILE_GROUP_KEY = this.FILE_GROUP_KEY8;
            this.FILE_KEY  = this.FILE_KEY8;
            this.clickDelFiles();
        },
        clickDelFiles(){
        if(this.FILE_GROUP_KEY.length != 0) {
            this.common_confirm('선택한 파일을 삭제하시겠습니까?',
                this.confirmDelYes, null, this.confirmDelNo, null);
        }
        },

        async confirmDelYes() {
            
            await this.updateBrInfoFileDelet();
            await this.deleteFile(this.FILE_GROUP_KEY , this.FILE_KEY , "001");
        },

        confirmDelNo() {
            return;
        },
        //*********************************
        //        첨부파일 업로드
        //*********************************
        // 파일첨부 버튼 클릭
        clickFileClsf1(){
            this.FILE_CLSF = "1";
            this.clickAddFiles();
        },
        clickFileClsf2(){
            this.FILE_CLSF = "2";
            this.clickAddFiles();
        },
        clickFileClsf3(){
            this.FILE_CLSF = "3";
            this.clickAddFiles();
        },
        clickFileClsf4(){
            this.FILE_CLSF = "4";
            this.clickAddFiles();
        },
        clickFileClsf5(){
            this.FILE_CLSF = "5";
            this.clickAddFiles();
        },
        clickFileClsf6(){
            this.FILE_CLSF = "6";
            this.clickAddFiles();
        },
        clickFileClsf7(){
            this.FILE_CLSF = "7";
            this.clickAddFiles();
        },
        clickFileClsf8(){
            this.FILE_CLSF = "8";
            this.clickAddFiles();
        },
        clickAddFiles() {
            let busiType = "brch";   //palette, phone, chat, km, bbs
            let pathType = "images"; //publics,files,images,videos,inqrytypeimage

            this.dropZoneParam = {
                ASP_NEWCUST_KEY : "001",
                fileGroupKey : this.FILE_GROUP_KEY,
                pathType : pathType, //publics,files,images,videos,inqrytypeimage
                busiType : busiType, //palette, phone, chat, km, bbs
                acceptedFiles : ".png,.jpg,.gif,.jpeg,.PNG,.JPG,.GIF,.JPEG",
                userOptions :{
                parallelUploads: 1,
                //url : process.env.VUE_APP_API_URL + "api/file/"+ busiType +"/"+ pathType +"/uploads",
                //url : "http://local.hkpalette.com:6001/" + "api/file/"+ busiType +"/"+ pathType +"/uploads",
                }
            };
            this.showDialog('VueDropzone');
        },
        // 첨부파일 팝업 업로드 후
        async uploadFileResponse(data){
            this.hideDialog('VueDropzone');
            // this.FILE_GROUP_KEY = data[0].fileGroupKey;
            console.log('data >>> ', data);

            if (this.FILE_CLSF == "1"){
                this.FILE_GROUP_KEY1 = data[0].fileGroupKey;
                this.FILE_KEY1 = data[0].fileKey;
                this.ORIGINAL_FILENAME1 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME1 == ''){ 
                    this.FILE_EXIST1 = false;
                }else{
                    this.FILE_EXIST1 = true;
                }
            }else if (this.FILE_CLSF == "2"){
                this.FILE_GROUP_KEY2 = data[0].fileGroupKey;
                this.FILE_KEY2 = data[0].fileKey;
                this.ORIGINAL_FILENAME2 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME2 == ''){ 
                    this.FILE_EXIST2 = false;
                }else{
                    this.FILE_EXIST2 = true;
                }
            }else if (this.FILE_CLSF == "3"){
                this.FILE_GROUP_KEY3 = data[0].fileGroupKey;
                this.FILE_KEY3 = data[0].fileKey;
                this.ORIGINAL_FILENAME3 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME3 == ''){ 
                    this.FILE_EXIST3 = false;
                }else{
                    this.FILE_EXIST3 = true;
                }
            }else if (this.FILE_CLSF == "4"){
                this.FILE_GROUP_KEY4 = data[0].fileGroupKey;
                this.FILE_KEY4 = data[0].fileKey;
                this.ORIGINAL_FILENAME4 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME4 == ''){ 
                    this.FILE_EXIST4 = false;
                }else{
                    this.FILE_EXIST4 = true;
                }
            }else if (this.FILE_CLSF == "5"){
                this.FILE_GROUP_KEY5 = data[0].fileGroupKey;
                this.FILE_KEY5 = data[0].fileKey;
                this.ORIGINAL_FILENAME5 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME5 == ''){ 
                    this.FILE_EXIST5 = false;
                }else{
                    this.FILE_EXIST5 = true;
                }
            }else if (this.FILE_CLSF == "6"){
                this.FILE_GROUP_KEY6 = data[0].fileGroupKey;
                this.FILE_KEY6 = data[0].fileKey;
                this.ORIGINAL_FILENAME6 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME6 == ''){ 
                    this.FILE_EXIST6 = false;
                }else{
                    this.FILE_EXIST6 = true;
                }
            }else if (this.FILE_CLSF == "7"){
                this.FILE_GROUP_KEY7 = data[0].fileGroupKey;
                this.FILE_KEY7 = data[0].fileKey;
                this.ORIGINAL_FILENAME7 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME7 == ''){ 
                    this.FILE_EXIST7 = false;
                }else{
                    this.FILE_EXIST7 = true;
                }
            }else if (this.FILE_CLSF == "8"){
                this.FILE_GROUP_KEY8 = data[0].fileGroupKey;
                this.FILE_KEY8 = data[0].fileKey;
                this.ORIGINAL_FILENAME8 = data[0].originalFilename;
                if (this.ORIGINAL_FILENAME8 == ''){ 
                    this.FILE_EXIST8 = false;
                }else{
                    this.FILE_EXIST8 = true;
                }
            }else{
                alert("프로그램 오류!!")
            }
        },
        async updateBrInfoFileDelet(){

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/setting/agent/brInfo/updateBrInfoFileDelet";
            requestData.headers["METHOD"] = "updateBrInfoFileDelet";
            requestData.headers["ASYNC"] = false;
            requestData.sendData["BR_SQNO"] = this.FILE_CLSF ;
            requestData.sendData["CHNG_MAN"] = this.geUserRole.userId;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                
                if (this.FILE_CLSF == "1"){
                    this.FILE_GROUP_KEY1 = '';
                    this.FILE_KEY1 ='';
                    this.ORIGINAL_FILENAME1 ='';
                    this.FILE_EXIST1 = false;
                }else if (this.FILE_CLSF == "2"){

                    this.FILE_GROUP_KEY2 = '';
                    this.FILE_KEY2 ='';
                    this.ORIGINAL_FILENAME2 ='';
                    this.FILE_EXIST2 = false;
                }else if (this.FILE_CLSF == "3"){

                    this.FILE_GROUP_KEY3 = '';
                    this.FILE_KEY3 ='';
                    this.ORIGINAL_FILENAME3 ='';
                    this.FILE_EXIST3 = false;
                }else if (this.FILE_CLSF == "4"){

                    this.FILE_GROUP_KEY4 = '';
                    this.FILE_KEY4 ='';
                    this.ORIGINAL_FILENAME4 ='';
                    this.FILE_EXIST4 = false;
                }else if (this.FILE_CLSF == "5"){
                    this.FILE_GROUP_KEY5 = '';
                    this.FILE_KEY5 ='';
                    this.ORIGINAL_FILENAME5 ='';
                    this.FILE_EXIST5 = false;
                }else if (this.FILE_CLSF == "6"){

                    this.FILE_GROUP_KEY6 = '';
                    this.FILE_KEY6 ='';
                    this.ORIGINAL_FILENAME6 ='';
                    this.FILE_EXIST6 = false;
                }else if (this.FILE_CLSF == "7"){

                    this.FILE_GROUP_KEY7 = '';
                    this.FILE_KEY7 ='';
                    this.ORIGINAL_FILENAME7 ='';
                    this.FILE_EXIST7 = false;
                }else if (this.FILE_CLSF == "8"){

                    this.FILE_GROUP_KEY8 = '';
                    this.FILE_KEY8 ='';
                    this.ORIGINAL_FILENAME8 ='';
                    this.FILE_EXIST8 = false;
                }
            }

        },
        //*********************************
        //           etc method
        //*********************************,
        showDialog(menu) {
            this[`dialog${menu}`] = true;
        },
        hideDialog(menu) {
            this[`dialog${menu}`] = false;
        },
    },
};
</script>

<style></style>  